import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  getTransactionListData,
  setFormData,
  clearFormData,
} from "../../Redux/Actions/genrateOfferPatient";
import { profileCodePermissionCheck } from "../../Utils/commonUtils";
export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#003c6e",
      light: "#003c6e",
      dark: "#003c6e",
    },
    secondary: {
      main: "#003c6e",
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      container: {
        width: "300px",
        height: "370px",
      },
      pickerView: {
        width: "280px",
        height: "280px",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: "0.9rem",
      },
      dayLabel: {
        fontSize: "0.7rem",
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: "0.8rem",
      },
    },
    MuiTypography: {
      h4: {
        fontSize: "1rem",
      },
    },
  },
});
const useStyles = makeStyles(styles);
const searchButtonStyles = makeStyles((theme) => ({
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
}));

function SearchTxn(props) {
  const classes = useStyles();
  const [showAmountRange, setShowAmountRange] = React.useState(false);
  const [showDateRange, setShowDateRange] = React.useState(false);
  const searchButtonClasses = searchButtonStyles();
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const [sender1, setSender1] = React.useState("");
  const [txn1, setTxn1] = React.useState("");
  const [amount1, setAmount1] = React.useState("");
  const [amount2, setAmount2] = React.useState("");
  const [date1, setDate1] = React.useState("");
  const [date2, setDate2] = React.useState("");
  const [status1, setStatus1] = React.useState("");

  React.useEffect(() => {
    const savedIsAmountRangeChecked = localStorage.getItem(
      "isAmountRangeChecked"
    );
    setShowAmountRange(savedIsAmountRangeChecked === "true");

    const savedIsDateRangeChecked = localStorage.getItem("isDateRangeChecked");
    setShowDateRange(savedIsDateRangeChecked === "true");
  }, []);

  const clearFormData = () => {
    const initialFormData = {
      searchSenderName: "",
      searchTxnID: "",
      searchStatus: null,
      searchStatusValue: "",
      searchStartAmount: "",
      searchEndAmount: "",
      searchStartDate: "",
      searchEndDate: "",
      dateRange: false,
      amountRange: false,
    };

    setSender1("");
    setTxn1("");
    setAmount1("");
    setAmount2("");
    setDate1("");
    setDate2("");
    setStatus1("");
    setShowAmountRange(false);
    setShowDateRange(false);

    localStorage.removeItem("isAmountRangeChecked");
    localStorage.removeItem("isDateRangeChecked");
    localStorage.removeItem("searchData");

    props.setFormData(initialFormData);
  };

  const handleAmountCheckboxChange = (event) => {
    setShowAmountRange(event.target.checked);
    if (event.target.checked) {
      setAmount1("");
      setShowAmountRange(true);
    } else {
      setShowAmountRange(false);
      setAmount1("");
      setAmount2("");
    }
  };

  const handleDateCheckboxChange = (event) => {
    setShowDateRange(event.target.checked);
    if (event.target.checked) {
      setDate1("");
      setShowDateRange(true);
    } else {
      setDate1("");
      setDate2("");
      setShowDateRange(false);
    }
  };

  const handleFromDateChange = (date) => {
    console.log(date, "Selected Date from Picker"); // Debugging

    if (!date) return;

    // Convert to a local date string without time shift
    const formattedDate = moment(date).format("YYYY-MM-DD");


    setDate1(formattedDate);
  };

  const handleToDateChange = (date) => {
    // console.log(date, "+++++++++++++++++++++++++++++++date")
    // let formattedDate = null;
    // if (date instanceof Date) {
    //   formattedDate = format(date, "yyyy-MM-dd");
    // } else if (typeof date === "string") {
    //   const parts = date.split("/");
    //   if (parts.length === 3) {
    //     formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    //   }
    // }
    // setDate2(formattedDate);

    console.log(date, "Selected Date from Picker"); // Debugging

    if (!date) return;

    // Convert to a local date string without time shift
    const formattedDate = moment(date).format("YYYY-MM-DD");

    setDate2(formattedDate);
  };

  const handleClose = () => {
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === profileCodePermissionCheck.profileCode.SA) {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 5,
        pageNo: 1,
      };
      props.getTransactionListData(payload);
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 5,
        pageNo: 1,
      };
      props.getTransactionListData(payload);
    }
    props.clearFormData();
    clearFormData();
    props.clearChips();
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "searchStatus") {
      setStatus1(value);
    }
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    const submitFormData = {
      searchSenderName: sender1.trim(),
      searchTxnID: txn1.trim(),
      searchStartAmount: amount1.trim(),
      searchEndAmount: amount2.trim(),
      searchStartDate: date1,
      searchEndDate: date2,
      searchStatus: status1.trim(),
      amountRange: showAmountRange,
      dateRange: showDateRange,
    };

    localStorage.setItem("searchData", JSON.stringify(submitFormData));
    if (showAmountRange === true) {
      localStorage.setItem("isAmountRangeChecked", true);
    } else {
      localStorage.removeItem("isAmountRangeChecked");
    }

    if (showDateRange === true) {
      localStorage.setItem("isDateRangeChecked", true);
    } else {
      localStorage.removeItem("isDateRangeChecked");
    }

    props.setFormData(submitFormData);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === profileCodePermissionCheck.profileCode.SA) {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 5,
        searchSenderName: sender1.trim(),
        searchTxnID: txn1.trim(),
        ...(showAmountRange
          ? {
            searchStartAmount: amount1.trim(),
            searchEndAmount: amount2.trim(),
          }
          : {
            searchStartAmount: amount1.trim(),
          }),
        ...(showDateRange
          ? {
            searchStartDate: date1,
            searchEndDate: date2,
          }
          : {
            searchStartDate: date1,
          }),
        searchStatus: status1.trim(),
        amountRange: showAmountRange,
        dateRange: showDateRange,
        pageNo: 1,
      };
      props.getTransactionListData(payload);
      props.seacrhChipData(payload);
      props.close();
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 5,
        searchSenderName: sender1.trim(),
        searchTxnID: txn1.trim(),
        ...(showAmountRange
          ? {
            searchStartAmount: amount1.trim(),
            searchEndAmount: amount2.trim(),
          }
          : {
            searchStartAmount: amount1.trim(),
          }),
        ...(showDateRange
          ? {
            searchStartDate: date1,
            searchEndDate: date2,
          }
          : {
            searchStartDate: date1,
          }),
        searchStatus: status1.trim(),
        amountRange: showAmountRange,
        dateRange: showDateRange,
        pageNo: 1,
      };
      props.getTransactionListData(payload);
      props.seacrhChipData(payload);
      props.close();
    }
  };

  React.useEffect(() => {
    if (props.formData.searchSenderName) {
      setSender1(props.formData.searchSenderName);
    }
    if (props.formData.searchTxnID) {
      setTxn1(props.formData.searchTxnID);
    }
    if (props.formData.searchStartAmount) {
      setAmount1(props.formData.searchStartAmount);
    }
    if (props.formData.searchEndAmount) {
      setAmount2(props.formData.searchEndAmount);
    }
    if (props.formData.searchStartDate) {
      setDate1(props.formData.searchStartDate);
    }
    if (props.formData.searchEndDate) {
      setDate2(props.formData.searchEndDate);
    }
    if (props.formData.searchStatus) {
      setStatus1(props.formData.searchStatus);
    }
  }, [props.formData]);

  const handleInputChange = (e) => {
    setSender1(e.target.value);
  };

  const handleInputChange1 = (e) => {
    setTxn1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setAmount1(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setAmount2(e.target.value);
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <form onSubmit={(e) => onFormSubmit(e)}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Sender"
              value={sender1}
              onChange={handleInputChange}
              name="searchSenderName"
              id="Sender Name"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Txn ID"
              value={txn1}
              onChange={handleInputChange1}
              name="searchTxnID"
              id="txnID"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
        >
          <Grid item xs container alignItems="center">
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLabelColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label={showAmountRange ? "From" : "Amount ($)"}
              value={amount1}
              onChange={handleInputChange2}
              name="searchStartAmount"
              id="searchStartAmount"
              style={{
                fontSize: "82%",
                width: showAmountRange ? "50%" : "100%",
              }}
            />
            {showAmountRange && (
              <>
                <TextField
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLabelColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  label="To"
                  value={amount2}
                  onChange={handleInputChange3}
                  name="searchEndAmount"
                  id="searchEndAmount"
                  style={{ fontSize: "82%", width: "50%" }}
                />
              </>
            )}
            <Checkbox
              checked={showAmountRange}
              onChange={handleAmountCheckboxChange}
              color={classes.activeInputColor}
              style={{ marginLeft: "auto" }}
            />
            <label style={{ fontSize: "12px", marginBottom: 0 }}>
              Search In Range
            </label>
          </Grid>
        </Grid>

        <Grid container className={classes.margin}>
          <Grid item xs container alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                variant="inline"
                label={showDateRange ? "From" : "Date"}
                value={date1 ? moment(date1, "YYYY-MM-DD").toDate() : null}
                onChange={handleFromDateChange}
                format="yyyy/MM/dd"
                maxDate={new Date()}
                style={{
                  width: showDateRange ? "50%" : "100%",
                }}
              />

              {(inputProps) => (
                <TextField
                  {...inputProps}
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLabelColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  label={showDateRange ? "From" : "Date"}
                  style={{
                    width: showDateRange ? "50%" : "100%",
                    fontSize: "100%",
                  }}
                />
              )}
            </MuiPickersUtilsProvider>
            {showDateRange && (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="To"
                    value={date2 ? moment(date2, "YYYY-MM-DD").toDate() : null}
                    onChange={handleToDateChange}
                    format="yyyy/MM/dd"
                    maxDate={new Date()}
                    minDate={date1 ? date1 : null} // Ensuring "To" date cannot be before "From" date
                    style={{
                      width: "50%",
                    }}
                  />
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      className={classes.rootDialog}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLabelColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize,
                        },
                      }}
                      label="To"
                      style={{ width: "50%", fontSize: "82%" }}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </>
            )}
            <Checkbox
              checked={showDateRange}
              onChange={handleDateCheckboxChange}
              color="primary"
              style={{ marginLeft: "auto" }}
            />
            <label style={{ fontSize: "12px", marginBottom: 0 }}>
              Search In Range
            </label>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Status"
              label="Status"
              value={status1}
              onChange={onChange}
              name="searchStatus"
              id="Current_Status"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem className={classes.dialogBoxDropDown} value="Reufnded">
                Refunded
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="Completed">
                Completed
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="InProgress"
              >
                InProgress
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="Canceled">
                Canceled
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            disabled={
              sender1 === ""
              && txn1 === ""
              && status1 === ""
              && amount1 === ""
              && amount2 === ""
              && date1 === ""
              && date2 === ""
            }
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.buttonStyle} ${searchButtonClasses.searchButton}`}
            disabled={
              sender1 === ""
              && txn1 === ""
              && status1 === ""
              && amount1 === ""
              && amount2 === ""
              && date1 === ""
              && date2 === ""
            }
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </form>
    </MuiThemeProvider>
  );
}

SearchTxn.propTypes = {
  close: PropTypes.func,
  tableData: PropTypes.object,
  seacrhChipData: PropTypes.func,
  getTransactionListData: PropTypes.func,
  deleteChp: PropTypes.any,
};

const mapStateToProps = (app) => ({
  formData: app.genrateOfferPatient,
  tableData: app.provideRegistration,
  userDetails: app.app,
  merchantData: app.genrateOfferPatient,
  transactionData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  setFormData,
  clearFormData,
  getTransactionListData,
})(SearchTxn);
