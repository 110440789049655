import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import { exportToPDF } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { format, subDays } from "date-fns";
import SyntaxHighlighter from "react-syntax-highlighter";
import { DialogContent } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { exportToCSVProvider } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import ApiLogsSearch from "../../components/DialogBox/ApiLogsSearch";
import LongMenu from "../../components/Menu/JsonIndex";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import SearchFields from "../../components/SearchComponent/SearchFields";
import SearchChips from "../../components/SearchComponent/SearchChips";
import {
  sendInviteApiCall,
  sendInviteManagerApiCall,
} from "../../actions/Operations";
import {
  getApiLogsData,
  emptyReduxState,
  getApiLogsDataExport,
  getApiLogsDataJson,
  setFormDataApiLogs,
  clearFormDataApiLogs,
} from "../../Redux/Actions/genrateOfferPatient";
import SyncIcon from "@material-ui/icons/Sync";
import CryptoJS from "crypto-js";
import AppLoader from "components/AppLoader/Index";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const codeStyle = {
  backgroundColor: "#282c34",
  padding: "1em",
  borderRadius: "4px",
  fontFamily: "Menlo, monospace",
  fontSize: "14px",
};

const useStyles = makeStyles(tableStyle);
function createData(
  _id,
  Type,
  Module_Name,
  Source,
  Request_Time,
  Response_Time,
  Requested_By,
  Requested_For,
  Request_JSON,
  Response_JSON,
  Status
) {
  return {
    _id,
    Type,
    Module_Name,
    Source,
    Request_Time,
    Response_Time,
    Requested_By,
    Requested_For,
    Request_JSON,
    Response_JSON,
    Status,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page ? page + 1 : 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "Type_Name",
      numeric: false,
      disablePadding: true,
      label: "Api Type",
      headClassName: classes.DBA_Name,
    },
    {
      id: "Module_Name",
      numeric: false,
      disablePadding: false,
      label: "Module",
      headClassName: classes.Assign_To,
    },
    {
      id: "Source",
      numeric: false,
      disablePadding: false,
      label: "Source",
      headClassName: classes.Assign_To,
    },
    {
      id: "Request_Time",
      numeric: false,
      disablePadding: false,
      label: "Request (time)",
      headClassName: classes.Assign_To,
    },
    {
      id: "Response_Time",
      numeric: true,
      disablePadding: false,
      label: "Response (time)",
      headClassName: classes.email,
    },
    {
      id: "Requested_By",
      numeric: true,
      disablePadding: false,
      label: "Requested By",
      headClassName: classes.email,
    },
    {
      id: "Requested_For",
      numeric: true,
      disablePadding: false,
      label: "Requested For",
      headClassName: classes.email,
    },
    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      headClassName: classes.Phone,
    },
    {
      id: "blank",
      numeric: false,
      disablePadding: false,
      label: "Action",
      headClassName: classes.status,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const ProviderReport = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(props.apiLogsData?.apiLogsData?.page);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Request_Time");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [updateDBAModal, setUpdateDBAModal] = React.useState(false);
  const menu = JSON.parse(localStorage.getItem("menu"));
  const [searchBar, setSearchBar] = React.useState(false);
  const [requestJsonData, setRequestJsonData] = React.useState();
  const [responseJsonData, setResponseJsonData] = React.useState();
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const copyToClipboardResponse = () => {
    navigator.clipboard.writeText(JSON.stringify(responseJsonData, null, 2));
  };
  const profileId = localStorage.getItem("profileId");
  const [searchDataFiled, setSearchDataFiled] = React.useState();
  const [handleBackPath, setHandleBack] = React.useState(false);
  console.log("props", props);
  console.log("searchDataFiled", searchDataFiled);
  const copyToClipboardRequest = () => {
    navigator.clipboard.writeText(JSON.stringify(requestJsonData, null, 2));
  };

  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("ID");
    if (idFromUrl) {
      setHandleBack(true);
    }
  }, []);

  React.useEffect(() => {
    debugger;
    setPage(1);
    setRowsPerPage(5);
    const today = new Date();
    const sevenDaysAgo = subDays(today, 7);
    const startDate = format(sevenDaysAgo, "yyyy-MM-dd");
    const endDate = format(today, "yyyy-MM-dd");
    const chips = [];
    const formattedStartDate = moment(startDate)
      .tz("America/New_York")
      .format("MM/DD/YY")
    // const formattedStartDate = format(new Date(startDate), "MM/dd/yy");
    const formattedEndDate = moment(endDate)
    .tz("America/New_York")
    .format("MM/DD/YY")
    // const formattedEndDate = format(new Date(endDate), "MM/dd/yy");
    localStorage.setItem("isDateRangeChecked", true);
    chips.push(`Date From: ${formattedStartDate} - To ${formattedEndDate}`);
    setChipValue(chips);
    const updatedFormData = {
      searchRequestedFor: "",
      searchApiType: "",
      searchApiTypeValue: "",
      searchModule: "",
      searchModuleValue: "",
      searchStartDate: "",
      searchEndDate: "",
      searchStatus: null,
      searchStartDate: startDate,
      searchEndDate: endDate,
      dateRange: true,
    };
    props.setFormDataApiLogs(updatedFormData);
    props.clearFormDataApiLogs();
    const payload = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      pageNo: 1,
      recordPerPage: rowsPerPage,
      pageNo: 1,
      recordPerPage: 5,
      sortColumn: orderBy,
      sortOrder: order,
      searchStartDate: startDate,
      searchEndDate: endDate,
      dateRange: true,
      amountRange: false,
    };
    props.getApiLogsData(payload);
    localStorage.removeItem("TransactionReport");
  }, []);

  const onClose = () => {
    setUpdateDBAModal(false);
  };
  const handleCloseModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  React.useEffect(() => {
    if (props?.apiLogsData?.apiLogsData?.page !== page) {
      setPage(props?.apiLogsData?.apiLogsData?.page);
    }
    if (props?.apiLogsData?.apiLogsData?.limit !== rowsPerPage) {
      setRowsPerPage(props?.apiLogsData?.apiLogsData?.limit);
    }
  }, [props]);

  React.useEffect(
    () => () => {
      let updatedFormData = {
        searchRequestedFor: "",
        searchApiType: "",
        searchApiTypeValue: "",
        searchModule: "",
        searchModuleValue: "",
        searchStartDate: "",
        searchEndDate: "",
        searchStatus: null,
        dateRange: false,
      };
      props.setFormDataApiLogs(updatedFormData);
      props.clearFormDataApiLogs();
    },
    []
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const payload = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      pageNo: newPage + 1,
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchRequestedFor: props?.apiLogsData?.searchRequestedFor || "",
      searchApiType: props?.apiLogsData?.searchApiType || "",
      searchModule: props?.apiLogsData?.searchModule || "",
      searchStatus: props?.apiLogsData?.searchStatus || "",
      searchStartDate: props?.apiLogsData?.searchStartDate || "",
      searchEndDate: props?.apiLogsData?.searchEndDate || "",
      dateRange: props?.apiLogsData?.dateRange,
    };
    props.getApiLogsData(payload);
  };

  const exportedBy =
    props.mainData?.users?.userDetailsById &&
    props.mainData?.users?.userDetailsById?.Users_Data[0]?.Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    setLoading(true);
    const filterData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      pageNo: 1,
      recordPerPage: props.merchantListData.apiLogsData.total,
      sortColumn: orderBy,
      sortOrder: order,
      searchRequestedFor: props?.apiLogsData?.searchRequestedFor || "",
      searchApiType: props?.apiLogsData?.searchApiType || "",
      searchModule: props?.apiLogsData?.searchModule || "",
      searchStatus: props?.apiLogsData?.searchStatus || "",
      searchStartDate: props?.apiLogsData?.searchStartDate || "",
      searchEndDate: props?.apiLogsData?.searchEndDate || "",
      dateRange: props?.apiLogsData?.dateRange,
    };
    props.getApiLogsDataExport(filterData, (data) => {
      setLoading(false);
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data.data, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const parsedData =
          encryptionKey == "NO" ? data.data : JSON.parse(decryptedData);
        const mappedLogs = parsedData.map((row) => ({
          Type: row.Type_Name,
          Module: row.Module_Name,
          Source: row.Source,
          "Request (time)": moment(row.Request_Time)
            .tz("America/New_York")
            .format("MM/DD/YYYY hh:mm A"),
          "Response (time)": moment(row.Response_Time)
            .tz("America/New_York")
            .format("MM/DD/YYYY hh:mm A"),
          "Requested By": `${row.Requested_By_Name || "NA"}\n(UID: ${
            row.Requested_By || "NA"
          })`,
          "Requested For": `${row.Requested_For_Name || "NA"}\n(UID: ${
            row.Requested_For || "NA"
          })`,
          Status: row.Status,
        }));
        exportToCSVProvider(
          mappedLogs,
          "API logs",
          chipValue,
          exportedBy,
          exportedByFname,
          exportedByLname
        );
      }
    });
  };

  const exportToPDFList = () => {
    setLoading(true);
    const filterData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      pageNo: 1,
      recordPerPage: props.merchantListData.apiLogsData.total,
      sortColumn: orderBy,
      sortOrder: order,
      searchRequestedFor: props?.apiLogsData?.searchRequestedFor || "",
      searchApiType: props?.apiLogsData?.searchApiType || "",
      searchModule: props?.apiLogsData?.searchModule || "",
      searchStatus: props?.apiLogsData?.searchStatus || "",
      searchStartDate: props?.apiLogsData?.searchStartDate || "",
      searchEndDate: props?.apiLogsData?.searchEndDate || "",
      dateRange: props?.apiLogsData?.searchEndDate || "",
    };
    props.getApiLogsDataExport(filterData, (data) => {
      const totalCount = data.data.length;
      setLoading(false);
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data.data, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const parsedData =
          encryptionKey == "NO" ? data.data : JSON.parse(decryptedData);
        const mappedLogs = parsedData.map((row) => [
          row.Type_Name,
          row.Module_Name,
          row.Source,
          moment(row.Request_Time)
            .tz("America/New_York")
            .format("MM/DD/YYYY hh:mm A"),
          moment(row.Response_Time)
            .tz("America/New_York")
            .format("MM/DD/YYYY hh:mm A"),
          `${row.Requested_By_Name || "NA"}\n(UID: ${
            row.Requested_By || "NA"
          })`,
          `${row.Requested_For_Name || "NA"}\n(UID: ${
            row.Requested_For || "NA"
          })`,
          row.Status,
        ]);

        const headersName = [
          "Type",
          "Module",
          "Source",
          "Request (time)",
          "Response (time)",
          "Requested By",
          "Requested For",
          "Status",
        ];
        exportToPDF(
          headersName,
          mappedLogs,
          "API Logs",
          chipValue,
          exportedBy,
          exportedByFname,
          exportedByLname,
          totalCount
        );
      }
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const payload = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      pageNo: 1,
      recordPerPage: parseInt(event.target.value, 10),
      sortColumn: orderBy,
      sortOrder: order,
      searchRequestedFor: props?.apiLogsData?.searchRequestedFor || "",
      searchApiType: props?.apiLogsData?.searchApiType || "",
      searchModule: props?.apiLogsData?.searchModule || "",
      searchStatus: props?.apiLogsData?.searchStatus || "",
      searchStartDate: props?.apiLogsData?.searchStartDate || "",
      searchEndDate: props?.apiLogsData?.searchEndDate || "",
      dateRange: props?.apiLogsData?.dateRange,
    };
    props.getApiLogsData(payload);
  };

  const displayAndDeleteChips = (searchData) => {
    const chips = [];
    if (searchData.searchStatus) {
      chips.push(`Status: ${searchData.searchStatus}`);
    }
    if (searchData?.searchRequestedFor) {
      chips.push(`Requested For: ${searchData?.searchRequestedFor}`);
    }
    if (
      // handleBackPath === true
      // &&
      searchData?.searchStartDate &&
      !searchData?.searchEndDate
    ) {
      const formattedStartDate = moment(searchData?.searchStartDate)
      .tz("America/New_York")
      .format("MM/DD/YY")
      // const formattedStartDate = format(
      //   new Date(searchData?.searchStartDate),
      //   "MM/dd/yy"
      // );
      if (searchData?.dateRange === true) {
        chips.push(`Date From: ${formattedStartDate} - To End`);
      } else {
        chips.push(`Date: ${formattedStartDate}`);
      }
    }
    if (
      // handleBackPath === true
      // &&
      !searchData?.searchStartDate &&
      searchData?.searchEndDate
    ) {
      const formattedEndDate = moment(searchData?.searchEndDate)
      .tz("America/New_York")
      .format("MM/DD/YY")
      // const formattedEndDate = format(
      //   new Date(searchData?.searchEndDate),
      //   "MM/dd/yy"
      // );
      if (searchData?.dateRange === true) {
        chips.push(`Date From: Start - To ${formattedEndDate}`);
      } else {
        chips.push(`Date: ${formattedEndDate}`);
      }
    }
    if (
      // handleBackPath === true
      // &&
      searchData?.searchStartDate &&
      searchData?.searchEndDate
    ) {
      const formattedStartDate = moment(searchData?.searchStartDate)
      .tz("America/New_York")
      .format("MM/DD/YY")
      // const formattedStartDate = format(
      //   new Date(searchData?.searchStartDate),
      //   "MM/dd/yy"
      // );
      const formattedEndDate = moment(searchData?.searchEndDate)
      .tz("America/New_York")
      .format("MM/DD/YY")
      // const formattedEndDate = format(
      //   new Date(searchData?.searchEndDate),
      //   "MM/dd/yy"
      // );
      chips.push(`Date From: ${formattedStartDate} - To ${formattedEndDate}`);
    }

    if (searchData.searchApiType) {
      let type = "";
      switch (searchData.searchApiType) {
        case 1:
          type = "RTN API";
          break;
        case 2:
          type = "Send Fund API";
          break;
        case 3:
          type = "Send Fee API";
          break;
        case 4:
          type = "Refund Fund API";
          break;
        default:
          type = "";
      }
      if (type) {
        chips.push(`API Type: ${type}`);
      }
    }
    if (searchData.searchModule) {
      let type = "";
      switch (searchData.searchModule) {
        case 1:
          type = "Invite Page";
          break;
        case 2:
          type = "Merchant Submit Page";
          break;
        case 3:
          type = "Make Payment Page";
          break;
        case 4:
          type = "Merchant SignUp Page";
          break;
        default:
          type = "";
      }
      if (type) {
        chips.push(`Module: ${type}`);
      }
    }
    setChipValue(chips);
  };

  const getModuleFromValue = (value) => {
    switch (value) {
      case "Invite Page":
        return 1;
      case "Merchant Submit Page":
        return 2;
      case "Make Payment Page":
        return 3;
      case "Merchant SignUp Page":
        return 4;
      default:
        return null;
    }
  };

  const getApiTypeFromValue = (value) => {
    switch (value) {
      case "RTN API":
        return 1;
      case "Send Fund API":
        return 2;
      case "Send Fee API":
        return 3;
      case "Refund Fund API":
        return 4;
      default:
        return null;
    }
  };

  const parseAndFormatDate = (dateString) => {
    const parts = dateString.split("/");
    let year = parts[2];
    if (year.length === 2) {
      const currentYear = new Date().getFullYear().toString();
      const currentCentury = currentYear.slice(0, 2);
      year = `${currentCentury}${year}`;
    }
    const month = parts[1].padStart(2, "0");
    const day = parts[0].padStart(2, "0");
    return `${year}-${day}-${month}`;
  };

  const handleDeleteChip = (chipToDelete) => {
    const updatedChips = chipValue.filter((chip) => chip !== chipToDelete);
    setChipValue(updatedChips);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    let payload;
    const updatedFormData = {
      searchRequestedFor: "",
      searchApiType: "",
      searchApiTypeValue: "",
      searchModule: "",
      searchEndAmount: "",
      searchStartDate: "",
      searchEndDate: "",
      searchModuleValue: "",
      searchStatus: null,
      dateRange: false,
    };

    if (updatedChips.length > 0) {
      updatedChips.forEach((chip) => {
        const [key, value] = chip.split(":").map((item) => item.trim());
        switch (key) {
          case "Requested For":
            updatedFormData["searchRequestedFor"] = value;
            break;
          case "API Type":
            updatedFormData["searchApiType"] = getApiTypeFromValue(value);
            updatedFormData["searchApiTypeValue"] = value;
            break;
          case "Module":
            updatedFormData["searchModule"] = getModuleFromValue(value);
            updatedFormData["searchModuleValue"] = value;
            break;
          case "Status":
            updatedFormData["searchStatus"] = value;
            break;
          case "Date":
            const formattedDate = parseAndFormatDate(value);
            updatedFormData["searchStartDate"] = formattedDate;
            updatedFormData["dateRange"] = false;
            break;
          case "Date From":
            const sdate =
              value === "Start" ? value : value.split(" - To ")[0].trim();
            const edate =
              value === "End" ? value : value.split(" - To ")[1].trim();
            const formattedStartDate =
              sdate === "Start" ? "" : parseAndFormatDate(sdate);
            const formattedEndDate =
              edate === "End" ? "" : parseAndFormatDate(edate);
            updatedFormData["dateRange"] = true;
            updatedFormData["searchStartDate"] = formattedStartDate;
            updatedFormData["searchEndDate"] = formattedEndDate;
            break;
          default:
            break;
        }
      });
    }
    setSearchDataFiled(updatedFormData);
    props.clearFormDataApiLogs();
    props.setFormDataApiLogs(updatedFormData);

    payload = {
      Profile_Code: profileCode,
      pageNo: page,
      recordPerPage: updatedChips.length > 0 ? rowsPerPage : 5,
      sortColumn: orderBy,
      sortOrder: order,
      ...updatedFormData,
    };
    props.getApiLogsData(payload);
    props.clearFormDataApiLogs();
  };

  rows = [];

  const result = props.apiLogsData.apiLogsData?.data?.map((items) => ({
    _id: items._id,
    Type: items?.Type_Name,
    Module_Name: items?.Module_Name,
    Source: items?.Source,
    Request_Time: items.Request_Time,
    Response_Time: items.Response_Time,
    Requested_By: items.Requested_By,
    Requested_For: items.Requested_For,
    Requested_By_Name: items.Requested_By_Name,
    Requested_For_Name: items.Requested_For_Name,
    Request_JSON: items.Request_JSON,
    Response_JSON: items.Response_JSON,
    Status: items.Status,
  }));
  result &&
    result.forEach((v, i) => {
      const _id = v._id;
      const Type = v.Type;
      const Module_Name = v.Module_Name;
      const Source = v.Source;
      const Request_Time = moment(v.Request_Time)
        .tz("America/New_York")
        .format("MM/DD/YYYY hh:mm A");
      const Response_Time = v.Response_Time
        ? moment(v.Response_Time)
            .tz("America/New_York")
            .format("MM/DD/YYYY hh:mm A")
        : "";
      const Requested_By = `${v.Requested_By_Name || "NA"}\n(UID: ${
        v.Requested_By || "NA"
      })`;
      const Requested_For = `${v.Requested_For_Name || "NA"}\n(UID: ${
        v.Requested_For || "NA"
      })`;
      const Request_JSON = v.Request_JSON;
      const Response_JSON = v.Response_JSON;
      const Status = v.Status;
      rows.push(
        createData(
          _id,
          Type,
          Module_Name,
          Source,
          Request_Time,
          Response_Time,
          Requested_By,
          Requested_For,
          Request_JSON,
          Response_JSON,
          Status
        )
      );
    });

  const handleUpdateDBAModal = (data) => {
    const bytes = CryptoJS.AES.decrypt(data.data, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    const parsedData =
      encryptionKey == "NO" ? data.data : JSON.parse(decryptedData);
    setRequestJsonData(JSON.parse(parsedData.Request_JSON));
    setResponseJsonData(JSON.parse(parsedData.Response_JSON));
    setUpdateDBAModal(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    const requestData = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      recordPerPage: rowsPerPage,
      sortColumn: property,
      sortOrder: newOrder,
      pageNo: page,
      searchRequestedFor: props?.apiLogsData?.searchRequestedFor || "",
      searchStartDate: props?.apiLogsData?.searchStartDate || "",
      searchEndDate: props?.apiLogsData?.searchEndDate || "",
      searchApiType: props?.apiLogsData?.searchApiType || "",
      searchModule: props?.apiLogsData?.searchModule || "",
      searchStatus: props?.apiLogsData?.searchStatus || "",
      dateRange: props?.apiLogsData?.dateRange,
    };
    props.getApiLogsData(requestData);
  };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleRefresh = () => {
    const payload = {
      Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      pageNo: page,
      searchRequestedFor: props?.apiLogsData?.searchRequestedFor || "",
      searchStartDate: props?.apiLogsData?.searchStartDate || "",
      searchEndDate: props?.apiLogsData?.searchEndDate || "",
      searchApiType: props?.apiLogsData?.searchApiType || "",
      searchModule: props?.apiLogsData?.searchModule || "",
      searchStatus: props?.apiLogsData?.searchStatus || "",
      dateRange: props?.apiLogsData?.dateRange,
    };

    props.getApiLogsData(payload);
  };

  return (
    <>
      <AppLoader loader={loading} />
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Modal
          open={updateDBAModal}
          onClose={onClose}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-labelledby="sasdasd"
          aria-describedby="simpleasdasdasdan"
        >
          <Zoom
            in={updateDBAModal}
            style={{ transitionDelay: updateDBAModal ? "250ms" : "0ms" }}
          >
            <Fade in={updateDBAModal}>
              <Paper
                className={classes.dialogBoxBorder}
                style={{
                  width: "910px",
                }}
              >
                <div className={classes.statusModalHeader}>
                  <div className={classes.statusHeadingText}>JSON Data</div>
                  <ClearIcon
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => onClose(false)}
                  />
                </div>

                <Grid container className={classes.margin}>
                  <Grid item xl={6} xs={6} md={6} lg={6}>
                    <Box
                      style={{
                        paddingLeft: "10px",
                        fontWeight: "bold",
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Request JSON
                      </Typography>

                      <Typography>
                        {" "}
                        <IconButton onClick={copyToClipboardRequest}>
                          <FileCopyIcon />
                        </IconButton>
                      </Typography>
                    </Box>

                    <DialogContent
                      style={{
                        height: "600px",
                      }}
                    >
                      <div className="code-editor">
                        <SyntaxHighlighter language="json" style={codeStyle}>
                          {JSON.stringify(requestJsonData, null, 2)}
                        </SyntaxHighlighter>
                      </div>
                    </DialogContent>
                  </Grid>

                  <Grid item xl={6} xs={6} md={6} lg={6}>
                    <Box
                      style={{
                        paddingLeft: "10px",
                        fontWeight: "bold",
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                        borderLeft: "1px solid #707070",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Response JSON
                      </Typography>

                      <Typography>
                        {" "}
                        <IconButton onClick={copyToClipboardResponse}>
                          <FileCopyIcon />
                        </IconButton>
                      </Typography>
                    </Box>
                    <DialogContent
                      style={{
                        height: "600px",
                        borderLeft: "1px solid #707070",
                      }}
                    >
                      <div className="code-editor">
                        <SyntaxHighlighter language="json" style={codeStyle}>
                          {JSON.stringify(responseJsonData, null, 2)}
                        </SyntaxHighlighter>
                      </div>
                    </DialogContent>
                  </Grid>
                </Grid>
              </Paper>
            </Fade>
          </Zoom>
        </Modal>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <ApiLogsSearch
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => {
                        setSearchDataFiled(data);
                        displayAndDeleteChips(data);
                      }}
                      clearChips={() => setChipValue([])}
                      order={order}
                      orderBy={orderBy}
                    />
                  )}
                />
                <IconsTooltip title="Refresh" placement="top">
                  <div className={classes.appSvgIcons}>
                    <SyncIcon
                      style={{ marginRight: "10px" }}
                      fontSize="large"
                      color="primary"
                      onClick={handleRefresh}
                    />
                  </div>
                </IconsTooltip>
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <ApiLogsSearch
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => {
                        setSearchDataFiled(data);
                        displayAndDeleteChips(data);
                      }}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />

                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
          </Grid>
        )}
        <SearchChips
          searchValues={chipValue}
          handleDeleteChip={(chip, index) => handleDeleteChip(chip, index)}
        />
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow className={classes.cellHeadSign} key={index}>
                  <TableCell className={classes.cellText}>{row.Type}</TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Module_Name}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Source}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Request_Time}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Response_Time}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Requested_By}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Requested_For}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Status}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    <LongMenu
                      handleUpdateDBAModal={() => {
                        const payload = { fetchJson: true, objId: row._id };
                        props.getApiLogsDataExport(payload, (data) => {
                          setLoading(false);
                          if (data) {
                            handleUpdateDBAModal(data);
                          }
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: props?.apiLogsData?.apiLogsData?.total,
                    },
                  ]}
                  colSpan={12}
                  count={props.apiLogsData.apiLogsData.total}
                  rowsPerPage={rowsPerPage || 5}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ProviderReport.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  userDetails: PropTypes.object,
  mainData: PropTypes.object,
  getMerchantListData: PropTypes.func,
  getApiLogsData: PropTypes.func,
  getApiLogsDataExport: PropTypes.func,
  getApiLogsDataJson: PropTypes.func,
  getManagerListDataByID: PropTypes.func,
  sendInviteApiCall: PropTypes.func,
  emptyReduxState: PropTypes.func,
  sendInviteManagerApiCall: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  merchantListData: app.genrateOfferPatient,
  apiLogsData: app.genrateOfferPatient,
  managerListData: app.genrateOfferPatient,
  managerListDataById: app.genrateOfferPatient,
});
export default connect(mapStateToProps, {
  getApiLogsData,
  getApiLogsDataExport,
  getApiLogsDataJson,
  sendInviteApiCall,
  emptyReduxState,
  sendInviteManagerApiCall,
  setFormDataApiLogs,
  clearFormDataApiLogs,
})(ProviderReport);
